html {
  // font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// import fonts for root

@font-face {
  font-family: 'Hind-Light';
  src: local('Hind-Light'), url(./assets/fonts/Hind-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Hind-Medium';
  src: local('Hind-Medium'), url(./assets/fonts/Hind-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Hind-Regular';
  src: local('Hind-Regular'), url(./assets/fonts/Hind-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Hind-SemiBold';
  src: local('Hind-SemiBold'), url(./assets/fonts/Hind-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Hind-Bold';
  src: local('Hind-Bold'), url(./assets/fonts/Hind-Bold.ttf) format('truetype');
}

table {
  background: #11122e !important;
  border-radius: 16px;
  border-color: #11122e !important;
  color: #fff;
  tr {
    border-bottom: 1px solid var(--neutral-700, #38383c) !important;
  }
  thead > tr > th {
    color: var(--neutral-300, #93939b) !important;
    background: #11122e !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    border-bottom: 1px solid var(--neutral-700, #38383c) !important;
  }
}

.ant-table-cell-row-hover {
  background-color: #11123e !important;
}

.ant-table-placeholder {
  pointer-events: none;
  background-color: inherit !important;
}

.ant-table-expanded-row-fixed:hover {
  background-color: #11123e !important;
}

.ant-empty-description {
  color: #fff !important;
}

.slick-dots li button:before {
  color: #221561 !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  color: #cecece !important;
}

.slick-active {
  outline: none;
  border: none !important;
  border-color: inherit !important;
  &:active {
    outline: none;
    border: none !important;
    border-color: inherit !important;
  }
}
